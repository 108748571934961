import { Component } from '@angular/core';
import { RouterModule } from '@angular/router'; // Import RouterModule



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true, // This indicates it's a standalone component
  imports: [RouterModule] // Include RouterModule here
})
export class AppComponent {
  // Component logic here
}

