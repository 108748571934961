<header>
  <nav>
    <div class="nav-container">
    <div class="logo-container">
      <img src="assets/images/CyborgLogo75.png" alt="CSC Logo" class="logo">
      <h1 class="company-name">Cybersecurity Cyborg</h1>
    </div>

    <ul class="nav-links" style="display: flex; justify-content: center; width: 100%;">
      <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>
      <li><a routerLink="/about" routerLinkActive="active">About</a></li>
      <li><a routerLink="/services" routerLinkActive="active">Products</a></li>
      <li><a routerLink="/news" routerLinkActive="active">News</a></li>
      <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
      <!--<li><a routerLink="/post" routerLinkActive="active">Post</a></li>-->
      <!--<li><a routerLink="/login" routerLinkActive="active">Login</a></li>-->
      <!-- <a routerLink="/post">Post Link</a> -->
    </ul>
    </div>
</nav>
</header>
<body>
<main>
  <div class="background-watermark"></div>
  <router-outlet></router-outlet>
</main>
<footer>
  <div class="footer-content">
    <div class="footer-info">
      <p>&copy; 2024 Cybersecurity Cyborg LLC. All rights reserved.</p>
    </div>
    <nav class="footer-nav">
      <ul>
        <li><a routerLink="/privacy">Privacy Policy</a></li>
        <li><a routerLink="/terms">Terms of Service</a></li>
      </ul>
    </nav>
  </div>
</footer>
</body>
