<div class="watermark"></div> <!-- Add watermark div outside the signup container -->

<div class="signup-container">
  <h2>Register Enterprise</h2>
  <form>
    <label for="companyName">Enterprise Name:</label>
    <input type="text" id="companyName" name="companyName" required>

    <label for="TaxId">Tax ID:</label>
    <input type="text" id="TaxId" name="TaxId" required>
    
    <div class="admin-account-container"> <!-- Bordered container for admin account -->
      <h3>Platform Admin Account</h3>
      
      <label for="email">Primary Email:</label>
      <input type="email" id="email" name="email" required>
      
      <label for="enterprisePassword">Enterprise Password:</label>
      <input type="password" id="enterprisePassword" name="enterprisePassword" required>
    </div>
    
    <button type="submit">Apply for EnterpriseID</button>
    <p class="notice">Applications are reviewed and EnterpriseID will be mailed to Primary Email in 2-3 Business Days.</p>
  </form>
</div>
