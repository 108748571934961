<div class="post-link"> 
    <div class="background-watermark"></div>
    <div class="posts-container">
        <article *ngIf="post; else loading">
            <div class="post">
                <!-- <img *ngIf="post.featuredImage" [src]="post.featuredImage" [alt]="post.title"> -->
                <h2 [innerHTML]="post.title.rendered"></h2>
                 <!-- <p class="meta">By {{ post.author }} on {{ post.date }}</p> -->
                <div [innerHTML]="post.content.rendered"></div>
            </div>
        </article>
        <ng-template #loading>
            <p>Loading...</p>
        </ng-template>

    </div>



