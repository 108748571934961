import { Component } from '@angular/core';

@Component({
  selector: 'app-products-and-services',
  standalone: true,
  imports: [],
  templateUrl: './products-and-services.component.html',
  styleUrl: './products-and-services.component.css'
})
export class ProductsAndServicesComponent {

}
