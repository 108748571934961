import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ProductsAndServicesComponent } from './products-and-services/products-and-services.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NewsAndEventsComponent } from './news-and-events/news-and-events.component';
// import { LoginComponent } from './login/login.component';
import { PostLinkComponent } from './post-link/post-link.component';
import { SignupComponent } from './signup/signup.component';
import { SignupCorporationComponent } from './signup-corporation/signup-corporation.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { environment } from './environments/environment';


export const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'services', component: ProductsAndServicesComponent },
  { path: 'about', component: AboutUsComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'news', component: NewsAndEventsComponent },
  { path: 'login', redirectTo: environment.directusUrl + '/admin/login/', pathMatch: 'full'  },
  { path: 'post/:id', component: PostLinkComponent },
  { path: 'post', component: PostLinkComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signup-corporation', component: SignupCorporationComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'directus', redirectTo: environment.directusUrl + 'admin/dashboard/', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
