<div class="watermark"></div> <!-- Add watermark div outside the signup container -->

<div class="signup-container">
  <h2>Sign Up Compliance Platform</h2>
  <form>
    <label for="username">Username:</label>
    <input type="text" id="username" name="username" required>
    
    <label for="email">Email:</label>
    <input type="email" id="email" name="email" required>
    
    <label for="password">Password:</label>
    <input type="password" id="password" name="password" required>
    
    <label for="EnterpriseId">Corporate ID (optional):</label>
    <input type="text" id="EnterpriseId" name="EnterpriseId">
    <p>If you know your EnterpriseID, enter it. If you would like to create a EnterpriseID, <a href="signup-corporation">click here</a>.</p>
    
    <button type="submit">Sign Up</button>
  </form>
</div>
