import { Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { WordpressService } from '../services/wordpress.service'; // Ensure this path is correct

@Component({
  selector: 'app-news-and-events',
  standalone: true,
  imports: [CommonModule, DatePipe, RouterLink],
  templateUrl: './news-and-events.component.html',
  styleUrls: ['./news-and-events.component.css']
})
export class NewsAndEventsComponent implements OnInit {
  posts: any[] = [];
  currentPage = 1;
  loading = false;

  constructor(private wordpressService: WordpressService) { }

  ngOnInit(): void {
    this.loadPosts();
  }

  loadPosts(): void {
    this.loading = true;
    this.wordpressService.getPosts(this.currentPage).subscribe(
      (newPosts) => {
        this.posts = [...this.posts, ...newPosts];
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching posts:', error);
        this.loading = false;
      }
    );
  }

  loadMore(): void {
    this.currentPage++;
    this.loadPosts();
  }
}