import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing'; // Ensure this import is present
import { AppComponent } from './app.component'; // Adjust the path if necessary
import { provideHttpClient, withFetch } from '@angular/common/http';
//import { PostLinkComponent } from './post-link/post-link.component'; // Adjust the import based on your structure

//import { RouterModule } from '@angular/router'; // Import RouterModule

@NgModule({
  declarations: [

  ],
  imports: [
    BrowserModule, // Import necessary Angular modules
    AppRoutingModule,
    CommonModule,
    AppComponent
  ],
  providers: [
    provideHttpClient(withFetch()) // Add this line to enable fetch
  ],
  // bootstrap: [AppComponent] // Ensure this is also present
})
export class AppModule { }
