import { Component } from '@angular/core';

@Component({
  selector: 'app-signup-corporation',
  standalone: true,
  imports: [],
  templateUrl: './signup-corporation.component.html',
  styleUrl: './signup-corporation.component.css'
})
export class SignupCorporationComponent {

}
