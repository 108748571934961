<div class="background-watermark"></div>
<div class="terms-of-service-container">
    <h2>Terms of Service</h2>
    <p><strong>Last Updated:</strong> October 24, 2024</p>

    <h3>1. Introduction</h3>
    <p>Welcome to Cybersecurity Cyborg LLC's compliance platform ("Platform"). By accessing or using our Platform, you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of these terms, you may not access the Platform.</p>

    <h3>2. Definitions</h3>
    <ul>
        <li><strong>"Company," "we," "us," or "our"</strong> refers to Cybersecurity Cyborg LLC</li>
        <li><strong>"User," "you," or "your"</strong> refers to any individual or entity using our Platform</li>
        <li><strong>"Services"</strong> refers to all features, functions, and services provided through our Platform</li>
        <li><strong>"Content"</strong> refers to all data, information, and materials uploaded to or processed through our Platform</li>
    </ul>

    <h3>3. Account Registration and Security</h3>
    <h4>3.1.</h4>
    <p>To access the Platform, you must create an account by providing accurate and complete information.</p>
    <h4>3.2. You are responsible for:</h4>
    <ul>
        <li>Maintaining the confidentiality of your account credentials</li>
        <li>All activities that occur under your account</li>
        <li>Immediately notifying us of any unauthorized use of your account</li>
        <li>Ensuring all users under your account comply with these Terms</li>
    </ul>

    <h3>4. Platform Usage and Restrictions</h3>
    <h4>4.1.</h4>
    <p>You agree to use the Platform only for lawful purposes and in accordance with these Terms.</p>
    <h4>4.2. You shall not:</h4>
    <ul>
        <li>Attempt to gain unauthorized access to the Platform</li>
        <li>Use the Platform to store or transmit malicious code</li>
        <li>Interfere with or disrupt the integrity of the Platform</li>
        <li>Share your account credentials with third parties</li>
        <li>Reverse engineer or decompile any part of the Platform</li>
        <li>Use the Platform for competitive analysis or benchmarking</li>
    </ul>

    <h3>5. Data Privacy and Security</h3>
    <h4>5.1.</h4>
    <p>Our collection and use of your data is governed by our Privacy Policy.</p>
    <h4>5.2. You retain all rights to your Content and grant us a license to:</h4>
    <ul>
        <li>Store and process your Content to provide the Services</li>
        <li>Create aggregated, anonymized data for analytics</li>
        <li>Improve and maintain the Platform</li>
    </ul>
    <h4>5.3.</h4>
    <p>We implement reasonable security measures but cannot guarantee absolute security.</p>

    <h3>6. Service Level Agreement</h3>
    <h4>6.1.</h4>
    <p>We strive to maintain 99.9% Platform availability, excluding scheduled maintenance.</p>
    <h4>6.2.</h4>
    <p>Scheduled maintenance will be communicated at least 48 hours in advance.</p>

    <h3>7. Payment Terms</h3>
    <h4>7.1.</h4>
    <p>You agree to pay all fees specified for your subscription plan.</p>
    <h4>7.2. All fees are:</h4>
    <ul>
        <li>Non-refundable</li>
        <li>Due in advance</li>
        <li>Exclusive of applicable taxes</li>
        <li>Subject to change with 30 days notice</li>
    </ul>

    <h3>8. Term and Termination</h3>
    <h4>8.1.</h4>
    <p>These Terms remain effective until terminated by either party.</p>
    <h4>8.2. We may terminate or suspend your account:</h4>
    <ul>
        <li>For breach of these Terms</li>
        <li>For non-payment</li>
        <li>At our discretion with 30 days notice</li>
    </ul>
    <h4>8.3. Upon termination:</h4>
    <ul>
        <li>All rights granted to you will cease</li>
        <li>You must cease all use of the Platform</li>
        <li>You may export your Content within 30 days</li>
    </ul>

    <h3>9. Warranties and Disclaimers</h3>
    <h4>9.1.</h4>
    <p>We warrant that the Platform will perform materially as described.</p>
    <h4>9.2.</h4>
    <p>THE PLATFORM IS PROVIDED "AS IS" WITHOUT ANY OTHER WARRANTIES, EXPRESS OR IMPLIED.</p>

    <h3>10. Limitation of Liability</h3>
    <h4>10.1.</h4>
    <p>IN NO EVENT SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES.</p>
    <h4>10.2.</h4>
    <p>OUR TOTAL LIABILITY SHALL NOT EXCEED THE AMOUNTS PAID BY YOU IN THE 12 MONTHS PRECEDING THE CLAIM.</p>

    <h3>11. Indemnification</h3>
    <p>You agree to indemnify and hold us harmless from any claims arising from:</p>
    <ul>
        <li>Your use of the Platform</li>
        <li>Your Content</li>
        <li>Your violation of these Terms</li>
    </ul>

    <h3>12. Changes to Terms</h3>
    <h4>12.1.</h4>
    <p>We reserve the right to modify these Terms at any time.</p>
    <h4>12.2.</h4>
    <p>Changes will be effective upon posting to the Platform.</p>
    <h4>12.3.</h4>
    <p>Continued use of the Platform constitutes acceptance of modified Terms.</p>

    <h3>13. Governing Law</h3>
    <p>These Terms shall be governed by and construed in accordance with the laws of [YOUR STATE], without regard to its conflict of law principles.</p>

    <h3>14. Contact Information</h3>
    <p>For questions about these Terms, please contact:</p>
    <p>Cybersecurity Cyborg LLC</p>
</div>
