<div class="news-and-events">
  <div class="background-watermark"></div>
  <h2 class="section-heading">News & Events</h2>
  <hr class="section-divider">
    <div class="posts-container">
      <article *ngFor="let post of posts" class="post">
        <img *ngIf="post.featuredImage" [src]="post.featuredImage" [alt]="post.title">
        <h3 [innerHTML]="post.title.rendered"></h3>
        <p class="meta">By {{ post.author }} on {{ post.date | date }}</p>
        <div [innerHTML]="post.excerpt.rendered"></div>
        <a [routerLink]="['/post', post.id]">Read More</a>
      </article>
    </div>
    <!-- <button *ngIf="!loading" (click)="loadMore()">Load More</button> 
    <p *ngIf="loading">Loading...</p> -->
  </div>