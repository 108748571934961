import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Define an interface for the Post structure
interface Post {
  id: number;
  title: { rendered: string };
  excerpt: { rendered: string };
  content: { rendered: string };
  date: string;
  _embedded: {
    author: { name: string }[];
    'wp:featuredmedia'?: { source_url: string }[];
  };
  link: string;
}

@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  private apiUrl = 'https://blog.cybersecuritycyborg.net/wp-json/wp/v2';

  constructor(private http: HttpClient) { }

  getPosts(page: number = 1): Observable<Post[]> {
    return this.http.get<Post[]>(`${this.apiUrl}/posts?page=${page}&_embed`)
      .pipe(
        map(posts => posts.map((post: Post) => ({
          id: post.id,
          title: { rendered: post.title.rendered },
          excerpt: { rendered: post.excerpt.rendered },
          content: { rendered: post.content.rendered },
          date: post.date,
          author: post._embedded.author[0].name,
          featuredImage: post._embedded['wp:featuredmedia'] 
            ? post._embedded['wp:featuredmedia'][0].source_url 
            : null,
          link: post.link,
          _embedded: post._embedded
        })))
      );
  }

  getPostById(postId: string | null): Observable<Post> {
    const url = `${this.apiUrl}/posts/${postId}`;
    return this.http.get<Post>(url)
      .pipe(
        map((post: Post) => {
          //console.log('Fetched post data:' + postId, post); // Log the fetched data
          return {
            id: post.id,
            title: { rendered: post.title.rendered },
            excerpt: { rendered: post.excerpt.rendered },
            content: { rendered: post.content.rendered },
            date: post.date,
            // author: {rendered: post._embedded.author[0].name},
            // featuredImage: post._embedded['wp:featuredmedia'] 
            //    ? post._embedded['wp:featuredmedia'][0].source_url 
            //    : null,
            link: post.link,
            _embedded: post._embedded
          };
        }),
        catchError(error => {
          //console.error('Error fetching post:', error); // Log the error
          return throwError(error); // Rethrow the error
        })
      );
  } 
}
