<div class="background-watermark"></div> <!-- Add watermark div outside the signup container -->
<div class="contact-us-container">
    <h2>Contact Us</h2>
    
    <p>If you have any questions, feedback, or inquiries, please feel free to reach out to us using the contact information below:</p>
    
    <h3>Email</h3>
    <p>You can email us at: <a href="mailto:info@cybersecuritycyborg.net">info&#64;cybersecuritycyborg.net</a></p>
    
    <h3>Follow Us</h3>
    <p>Connect with us on social media:</p>
    <ul>
        <li><a href="https://www.linkedin.com/company/cybersecurity-cyborg/" target="_blank">LinkedIn</a></li>
        <li><a href="https://www.facebook.com/profile.php?id=61561402695466" target="_blank">Facebook</a></li>
    </ul>
    
    <h3>General Instructions</h3>
    <p>Please provide as much detail as possible in your message to help us assist you better. We aim to respond to all inquiries within 1-2 business days.</p>
    
    <h3>Office Hours</h3>
    <p>Our office hours are Monday to Friday, 9 AM to 5 PM (EST). We look forward to hearing from you!</p>
</div>
