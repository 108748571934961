<div class="landing-page-container">
  <div class="background-watermark"></div>
  <main>
    <section id="home" class="hero">
      <h1 class="main-heading">Welcome to Cybersecurity Cyborg</h1>
      <p class="tagline">Your partner in cybersecurity compliance.</p>
    </section>

    <section class="description-box">
      <p>Protect and monitor your Risk Posture and Security Compliance with CyberSecurity Cyborg, the ultimate cybersecurity compliance platform. Maximize your Cybersecurity spend for both Compliance and Risk Reduction. Utilize our unique hybrid model of AI and human experts to provide comprehensive and scalable solutions that can optimize cybersecurity and help you become more agile and resilient.</p>
    </section>

    <div class="logo-container">
      <img src="assets/images/CyborgLogo.jpeg" alt="Cybersecurity Cyborg Logo" class="company-logo">
    </div>

    <section id="compliance" class="compliance">
      <h1>Supporting Industry, Regional, and Regulatory compliance controls and frameworks.</h1>
      <div class="compliance-badges">
        <img src="assets/images/CMMCIcon.png" alt="CMMC Icon" class="badge">
        <img src="assets/images/COBIT-5-1024x341.jpg" alt="COBIT 5" class="badge">
        <img src="assets/images/GDRP.png" alt="GDPR" class="badge">
        <img src="assets/images/HIPAA.png" alt="HIPAA" class="badge">
        <img src="assets/images/NIST_CSF.webp" alt="NIST CSF" class="badge">
        <img src="assets/images/NIST800-171.png" alt="NIST 800-171" class="badge">
        <img src="assets/images/pci_logo-1.png" alt="PCI" class="badge">
      </div>
    </section>
  </main>
</div>
