import { Component, OnInit } from '@angular/core';
import { CommonModule} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { WordpressService } from '../services/wordpress.service';

@Component({
  selector: 'app-post-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './post-link.component.html',
  styleUrls: ['./post-link.component.css']
})
export class PostLinkComponent implements OnInit {
  post: any;

  constructor(private route: ActivatedRoute, private wordpressService: WordpressService) {}

  ngOnInit(): void {
    const postId = this.route.snapshot.paramMap.get('id');
    console.log('Post ID:', postId);
    if (postId) {
      this.wordpressService.getPostById(postId).subscribe({
        next: (post) => {
          this.post = post;
          //console.log('Loaded post:', this.post);
        },
        error: (err) => {
          //console.error('Error loading post:', err);
        }
      });
    } else {
      console.error('No post ID found in route parameters.');
    }
  }
}
