<div class="background-watermark"></div>
<div class="privacy-policy-container">
    <h2>Privacy Policy</h2>
    <p><strong>Last Updated:</strong> October 24, 2024</p>

    <h3>1. Introduction</h3>
    <p>Welcome to Cybersecurity Cyborg LLC ("we," "our," or "us"). We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>

    <h3>2. Information We Collect</h3>
    
    <h4>2.1 Personal Information</h4>
    <p>We may collect personal information that you voluntarily provide to us, including but not limited to:</p>
    <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Billing address</li>
        <li>Payment information</li>
        <li>Company name</li>
        <li>Job title</li>
    </ul>

    <h4>2.2 Automatically Collected Information</h4>
    <p>When you visit our website, we automatically collect certain information, including:</p>
    <ul>
        <li>IP address</li>
        <li>Browser type</li>
        <li>Device information</li>
        <li>Operating system</li>
        <li>Log data</li>
        <li>Cookies and similar tracking technologies</li>
    </ul>

    <h3>3. How We Use Your Information</h3>
    <p>We use your information for the following purposes:</p>
    <ul>
        <li>To provide and maintain our services</li>
        <li>To process your transactions</li>
        <li>To send you administrative information</li>
        <li>To respond to your inquiries</li>
        <li>To improve our website and services</li>
        <li>To send marketing communications (with your consent)</li>
        <li>To comply with legal obligations</li>
        <li>To detect and prevent fraud</li>
    </ul>

    <h3>4. Information Sharing</h3>
    <p>We may share your information with:</p>
    <ul>
        <li>Service providers and business partners</li>
        <li>Legal authorities when required by law</li>
        <li>Affiliated companies</li>
        <li>Third parties in the event of a merger, acquisition, or business transfer</li>
    </ul>

    <h3>5. Data Security</h3>
    <p>We implement appropriate technical and organizational measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.</p>

    <h3>6. Your Rights</h3>
    <p>You have the right to:</p>
    <ul>
        <li>Access your personal information</li>
        <li>Correct inaccurate information</li>
        <li>Request deletion of your information</li>
        <li>Object to processing</li>
        <li>Withdraw consent</li>
        <li>Request data portability</li>
    </ul>

    <h3>7. Cookies</h3>
    <p>We use cookies and similar tracking technologies to improve your browsing experience. You can control cookies through your browser settings.</p>

    <h3>8. Children's Privacy</h3>
    <p>Our services are not intended for children under 13. We do not knowingly collect information from children under 13.</p>

    <h3>9. Third-Party Links</h3>
    <p>Our website may contain links to third-party websites. We are not responsible for their privacy practices.</p>

    <h3>10. Changes to Privacy Policy</h3>
    <p>We reserve the right to modify this Privacy Policy at any time. Changes will be effective immediately upon posting to our website.</p>

    <h3>11. Contact Us</h3>
    <p>For questions about this Privacy Policy, please contact us at:</p>
    <p>
        Cybersecurity Cyborg LLC<br>
        Email: <a href="mailto:info@cybersecuritycyborg.net">info&#64;cybersecuritycyborg.net</a><br>
    </p>
</div>
